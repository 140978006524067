import React, { useState, useEffect } from "react";
import Container from "./container";
import { cn, seoUrl, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { Link } from "react-scroll";

import * as styles from "../styles/components/product-page-header.module.css";

const PageHeader = (props) => {
  const {
    title,
    bg,
    color,
    overview,
    features,
    howItWorks,
    integrations,
    technology,
    inAction,
    dynamic
  } = props;

  // Get viewport size
  const hasWindow = typeof window !== "undefined";
  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );
    useEffect(() => {
      if (hasWindow) {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }
    }, []);
    return windowDimensions;
  }

  const { width } = useWindowDimensions();

  return (
    <>
      <div className={styles.root}>
        <Container>
          {title && (
            <div className={styles.contentWrap}>
              <h1 className={cn(styles.title, dynamic && 'color-palette-color', color === 'dark-green' && styles.darkGreenTitle)}>{title}</h1>
            </div>
          )}
          {bg && bg.asset && (
            <div className={styles.bg}>
              <img
                src={imageUrlFor(buildImageObj(bg)).auto("format").url()}
                alt=""
              />
            </div>
          )}
        </Container>
      </div>
      {(overview || features || integrations || technology || inAction) && (
        <div className={cn(styles.anchors, "m-hide")}>
          <ul>
            {overview && (
              <li>
                <Link
                  to={seoUrl(overview)}
                  smooth={true}
                  hashSpy={true}
                  offset={width >= 1500 ? -136 : width >= 960 ? -112 : -50}
                  duration={800}
                  delay={0}
                  isDynamic={true}
                  ignoreCancelEvents={false}
                  spyThrottle={0}
                  spy={true}
                  activeClass={styles.active}
                >
                  {overview}
                </Link>
              </li>
            )}
            {features && (
              <li>
                <Link
                  to={seoUrl(features)}
                  smooth={true}
                  hashSpy={true}
                  offset={width >= 1500 ? -136 : width >= 960 ? -112 : -50}
                  duration={800}
                  delay={0}
                  isDynamic={true}
                  ignoreCancelEvents={false}
                  spyThrottle={0}
                  spy={true}
                  activeClass={styles.active}
                >
                  {features}
                </Link>
              </li>
            )}
            {howItWorks && (
              <li>
                <Link
                  to={seoUrl(howItWorks)}
                  smooth={true}
                  hashSpy={true}
                  offset={width >= 1500 ? -136 : width >= 960 ? -112 : -50}
                  duration={800}
                  delay={0}
                  isDynamic={true}
                  ignoreCancelEvents={false}
                  spyThrottle={0}
                  spy={true}
                  activeClass={styles.active}
                >
                  {howItWorks}
                </Link>
              </li>
            )}
            {integrations && (
              <li>
                <Link
                  to={seoUrl(integrations)}
                  smooth={true}
                  hashSpy={true}
                  offset={width >= 1500 ? -136 : width >= 960 ? -112 : -50}
                  duration={800}
                  delay={0}
                  isDynamic={true}
                  ignoreCancelEvents={false}
                  spyThrottle={0}
                  spy={true}
                  activeClass={styles.active}
                >
                  {integrations}
                </Link>
              </li>
            )}
            {technology && (
              <li>
                <Link
                  to={seoUrl(technology)}
                  smooth={true}
                  hashSpy={true}
                  offset={width >= 1500 ? -136 : width >= 960 ? -112 : -50}
                  duration={800}
                  delay={0}
                  isDynamic={true}
                  ignoreCancelEvents={false}
                  spyThrottle={0}
                  spy={true}
                  activeClass={styles.active}
                >
                  {technology}
                </Link>
              </li>
            )}
            {inAction && (
              <li>
                <Link
                  to={seoUrl(inAction)}
                  smooth={true}
                  hashSpy={true}
                  offset={width >= 1500 ? -136 : width >= 960 ? -112 : -50}
                  duration={800}
                  delay={0}
                  isDynamic={true}
                  ignoreCancelEvents={false}
                  spyThrottle={0}
                  spy={true}
                  activeClass={styles.active}
                >
                  {inAction}
                </Link>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default PageHeader;
