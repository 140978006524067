import React from "react";
import FlexibleContent from "../components/flexible-content";

const ProductMain = (props) => {
  const {  content } = props;

  return content && <FlexibleContent items={content} />;
};

export default ProductMain;
